import Container from "react-bootstrap/Container";
import Image from 'react-bootstrap/Image';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useLocation } from "react-router-dom";
import FooterComponent from "../../components/FooterComponent";

const RulesPage = () => {
  const { state } = useLocation();

  return (
    <>
      <div className="menupush">
        <div className="page_regulamento">
          <section className="sec sec_regulamento ">
            <Container>
              <header className="sec_header">
                <h2 className="title sec_title">REGULAMENTO</h2>
              </header> 
              <div className="sec_content">
              <div className="regulamento-text ">
                        <h3 className="title">"BUNGE NO FIM DE ANO DA SORTE TENDA ATACADO"</h3>
                        <ol>
                            <li>
                                <h4> EMPRESAS PROMOTORAS:</h4>
                                <ol>
                                    <li>Empresa Mandatária
                                        <ol>
                                            <li>Razão Social: Pax Marketing e Eventos Ltda </li>
                                            <li>Endereço: Av. Tancredo Neves, 620 – Caminho das Árvores – Salvador/BA</li>
                                            <li>CNPJ nº. 34.394.645/0001-78 </li>
                                        </ol>
                                    </li>
                                    <li>Empresa Aderente
                                        <ol >
                                            <li>Razão Social: Bunge Alimentos S.A.</li>
                                            <li>Endereço: Rua Diogo Moreira, n. 184, 5º ao 14ª andar, Pinheiros, CEP 05423-010, São Paulo/SP</li>
                                            <li>CNPJ nº. 84.046.101/0574-62</li>

                                            <li>Razão Social: Tenda Atacado S.A</li>
                                            <li>Endereço: Rua Professor João Cavalheiro Salem, 231 – Parque das Nações – Guarulhos/SP</li>
                                            <li>CNPJ nº. 01.157.555/0011-86 </li>
                                        </ol>
                                    </li>
                                    <p>A Empresa Mandatária e as Empresas Aderentes são referidas neste documento em conjunto como “Promotoras” ou “Empresas Promotoras”.</p>
                                </ol>
                            </li>
                            <li>
                                <h4>MODALIDADE DA PROMOÇÃO:</h4>
                                <ol>
                                  <li>Assemelhado a Sorteio</li>     
                                </ol>
                            </li>
                            <li>
                                <h4>ÁREA DE ABRANGÊNCIA:</h4>
                                <ol>
                                  <li>Estado de São Paulo</li>
                                </ol>
                            </li>

                            <li>
                                <h4>CRITÉRIO DE PARTICIPAÇÃO:</h4>
                                <ol>
                                  <li>
                                    Esta promoção <b>“Bunge no fim de ano da sorte Tenda Atacado”</b> doravante designada “PROMOÇÃO” a ser realizada pelas empresas “Bunge” e “Tenda Atacado” doravante designada “Promotoras”, no período de participação compreendido entre às 00h00 do dia 06 de novembro de 2024 até às 23h59 do dia 31 de dezembro de 2024 (horário oficial de Brasília), é permitida a participação de pessoas jurídicas (CNPJ) e pessoa física (CPF) com idade igual ou superior a 18 anos, residente e domiciliada em território nacional, interessada em participar e que cumpram o disposto neste regulamento.
                                  </li>                                  
                                  <li>
                                    Para participar da promoção <b>“Bunge no fim de ano da sorte Tenda Atacado”</b>, o consumidor que realizar a compra de 01 (um) Fardo e/ou 05 (cinco) unidades de produtos da família/linha Suprema participantes da promoção, no Tenda Atacado participante da promoção (lojas físicas, e-commerce e televendas), no período entre o dia 06 de novembro de 2024 até o dia 31 de dezembro de 2024 e deverá se cadastrar na promoção através do hotsite <a href="https://www.fimdeanodasorte.com.br" target="_blank">www.fimdeanodasorte.com.br</a> para obter os números da sorte a que tiver direito na promoção.
                                    <ol>
                                      <li>Estarão participando da promoção <b>“Bunge no fim de ano da sorte Tenda Atacado”</b> os produtos da família/linha Suprema, relacionados abaixo, sendo que para participar da promoção serão válidos apenas comprovantes fiscais emitidos em uma das unidades Tenda Atacado participante da promoção, que contenha ao menos 01 (um) Fardo e/ou 05 (cinco) unidades de produtos da família/linha Suprema participantes da promoção, durante o período da promoção do dia 06 de novembro de 2024 até o dia 31 de dezembro de 2024.</li>
                                      <li>Produtos da família/linha Suprema participantes da promoção:
                                        <br />
                                        <div className="table-responsive">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Código do Produto Bunge</th>
                                                <th>Descrição do produto</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>135085</td>
                                                <td>FARINHA TRIGO PIZZA SUPREMA 5X5KG PAP</td>
                                              </tr>

                                              <tr>
                                                <td>135123</td>
                                                <td>FARINHA TRIGO PASTEL SUPREMA 5X5KG PLA</td>
                                              </tr>
                                              <tr>
                                                <td>135131</td>
                                                <td>FARINHA TRIGO SALGADOS SUPREMA 5X5KG PLA</td>
                                              </tr>
                                              <tr>
                                                <td>149862</td>
                                                <td>FARINHA TRIGO SUPREMA GRANOS 5KG</td>
                                              </tr>
                                              <tr>
                                                <td>151225</td>
                                                <td>FARINHA TRIGO CONFEIT SUPREMA 5X5KG PAP</td>
                                              </tr>
                                              <tr>
                                                <td>156386</td>
                                                <td>FARINHA TRIGO SUPREMA PREMIATA 5X5KG PAP</td>
                                              </tr>
                                              <tr>
                                                <td>156597</td>
                                                <td>FARINHA TRIGO SUPREMA PAO FN 5X5KG PAP</td>
                                              </tr>
                                              <tr>
                                                <td>156133</td>
                                                <td>GORDURA SUPREMA GOLDEN LT GALAO 2X2,5KG</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </li>
                                    </ol>  
                                  </li>                                  
                                  <li>
                                    Os consumidores participantes terão direito a 01 (um) número da sorte a cada 01 (um) Fardo e/ou 05 (cinco) unidades de produtos da família/linha Suprema participantes adquiridos no mesmo comprovante fiscal, emitido em uma das unidades Tenda Atacado participante da promoção. Caso a compra contenha 01 (uma) unidade de Farinha de Trigo Select Tenda de 5kg e/ou Farinha de Trigo Farina de 5kg (produtos aceleradores), o consumidor participante terá direito a números da sorte em dobro, desde que, observadas as condições deste Regulamento.
                                    <ol>
                                      <li>
                                        Produtos aceleradores da promoção, que darão direito a números da sorte em dobro:
                                        <br />
                                        <div className="table-responsive">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Código do Produto </th>
                                                <th>Descrição do produto</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>153433</td>
                                                <td>FARINHA TRIGO SELECT TENDA 5X5KG PLA</td>
                                              </tr>
                                              <tr>
                                                <td>153454</td>
                                                <td>FARINHA TRIGO SELECT TEN PAST 5X5KG PLA</td>
                                              </tr>
                                              <tr>
                                                <td>153461</td>
                                                <td>FARINHA TRIGO SELECT TEN PIZZA 5X5KG PLA</td>
                                              </tr>
                                              <tr>
                                                <td>156591</td>
                                                <td>FARINHA TRIGO T1 FARINA 5X5KG PAP</td>
                                              </tr>
                                              
                                            </tbody>
                                          </table>
                                        </div>
                                      </li>
                                      <li>Não haverá limite de números da sorte por consumidor, podendo cada consumidor participante concorrer com quantos números da sorte tiver direito.</li>
                                      <li>O comprovante fiscal será vinculado ao CPF/CNPJ cadastrado na promoção, e desde que o cadastro tenha sido efetivado no hotsite <a href="https://www.fimdeanodasorte.com.br" target="_blank">www.fimdeanodasorte.com.br</a> durante o período da promoção.</li>
                                      <li>Ficam os consumidores participantes, cientes, desde já, que a participação na promoção é individualizada, e não poderá, em hipótese alguma, transferir e/ou dividir com outro consumidor qualquer quantidade residual, independentemente do grau de parentesco e/ou amizade. Da mesma forma, não será admitida, por força de legislação fiscal, “divisão de notas fiscais” entre consumidores no ato da compra.</li>
                                    </ol>
                                  </li>
                                  <li>
                                    O consumidor interessado em participar da promoção <b>“Bunge no fim de ano da sorte Tenda Atacado”</b> deverá acessar o hotsite <a href="https://www.fimdeanodasorte.com.br" target="_blank">www.fimdeanodasorte.com.br</a> no período entre às 00h00 do dia 06 de novembro de 2024 até às 23h59 do dia 31 de dezembro de 2024 (horário oficial de Brasília) para realizar o cadastro utilizando seus dados pessoais [(Nome completo ou Razão Social), (CPF ou CNPJ), número de telefone, data de nascimento e e-mail] e upload do comprovante fiscal, por meio de foto e/ou leitura de QR Code e informar os dados da compra (Loja participante, Valor da nota, Quantidade de pacotes participantes da promoção, Compras com acelerador e chave de acesso da NF), aguardando completo envio dos referidos comprovantes e a confirmação de validação pelo sistema.
                                    <ol>
                                      <li>Uma vez realizado o cadastro no site, não serão permitidas alterações do CPF/CNPJ e dos Cupons Fiscais já cadastrados, portanto é importante que o consumidor preencha corretamente os dados antes de concluir o cadastro e mantenha seus dados cadastrais atualizados. Cada consumidor é responsável por realizar seu próprio cadastro, não devendo realizar cadastros em nome de terceiros, sob pena de desclassificação.</li>

                                      <li>A empresa promotora não será responsável quando, em razão do fornecimento de dados incompletos ou incorretos pelo consumidor, ficar impossibilitada de realizar a entrega do prêmio ao respectivo ganhador, momento em que haverá a comunicação junto as Promotoras, e na sequência será apurada nova contemplação em seu lugar, seguindo a regras desta promoção.</li>

                                      <li>Para o cadastro de novos cupons fiscais, basta o consumidor acessar o hotsite <a href="https://www.fimdeanodasorte.com.br" target="_blank">www.fimdeanodasorte.com.br</a> com seu login, senha e cadastrar comprovante fiscal, por meio de foto e/ou leitura de QR Code e informar no cadastro a quantidade de produtos participantes adquiridos.</li>

                                      <li>Os dados de cada Cupom Fiscal informados pelos consumidores poderão ser cadastrados somente uma única vez, caso seja identificado que o mesmo Cupom Fiscal, tenha sido cadastrado mais de uma vez e/ou que tenha sido cadastrado por dois ou mais consumidores os mesmos serão desclassificados.</li>

                                      <li>Se por qualquer motivo o consumidor inserir no cadastro da promoção qualquer informação diversa do que consta no cupom fiscal de compra, os números da sorte emitidos poderão ser automaticamente anulados pela empresa promotora, sem prévia comunicação ao consumidor, e na hipótese de serem contemplados não dará direito ao prêmio.</li>

                                      <li>O fornecimento de informações falsas ou incorretas no cadastro feito através do site da promoção implicará na desclassificação da nota/cupom cadastrado. Esta prática poderá, ainda, caracterizar crime, sujeitando o infrator às penalidades previstas na legislação em vigor.</li>

                                      <li>Em caso de cancelamento da compra (devolução dos produtos por desistência da compra) ou troca do produto, os números da sorte serão automaticamente invalidados para participação no sorteio da promoção.
                                      </li>
                                    </ol>
                                  </li>
                                  <li>
                                    Serão aceitos somente cupons fiscais (NFCe e SAT) válidos e dentro do padrão estabelecido pela legislação vigente. Não será permitida a participação por meio de cadastro de notas fiscais manuais, comprovantes de pagamento de cartão de crédito ou de débito ou comprovantes fiscais que não possam ter sua validade regularmente conferidas perante a SEFAZ. Excepcionalmente, será admitido o cadastro de cupons fiscais (NFCe e SAT) e produtos participantes, caso a consulta pública da SEFAZ fique indisponível por tempo indeterminado.
                                  </li>
                                  <li>
                                    O consumidor participante deverá guardar o cupom fiscal/nota fiscal de sua compra e apresentá-lo à empresa promotora caso seja contemplado no prazo de até 72 (setenta e duas) horas do efetivo contato e solicitação feita pelas Promotoras da campanha sob pena de desclassificação e não recebimento do prêmio caso o cupom fiscal cadastrado não seja apresentado ou não atenda aos requisitos desta promoção.
                                  </li>
                                  <li>
                                    Após a conclusão do cadastro, será informado ao consumidor, automaticamente, a confirmação da inscrição na promoção <b>“Bunge no fim de ano da sorte Tenda Atacado”</b>, a quantidade e o números da sorte que este teve direito, conforme o período da promoção.
                                    <ol>
                                      <li>Caso o consumidor não consiga concluir sua inscrição, o mesmo será informado o motivo do cancelamento da mesma.</li>
                                      <li>Somente serão válidas para a promoção as compras realizadas no período compreendido entre as 00h00 do dia 06 de novembro de 2024 até às 23h59 do dia 31 de dezembro de 2024 (horário oficial de Brasília).</li>
                                      <li>Todos os consumidores participantes da promoção serão gerenciados diretamente pelo Sistema da empresa promotora “Tenda Atacado” de forma integrada, sendo que o consumidor contemplado será identificado por meio do banco de dados decorrente do cadastro realizado para esta Promoção. A geração dos números da sorte eletrônicos será efetuada por meio da distribuição concomitante de Números de Série e Números de Ordem às transações válidas, de forma equitativa e aleatória, sendo o contemplado será definido com base no resultado da extração da Loteria Federal de acordo com o Art. 2º da portaria 41.</li>
                                      <li>O consumidor poderá consultar todos os seus números da sorte os quais teve direito durante a promoção através do site <a href="https://www.fimdeanodasorte.com.br" target="_blank">www.fimdeanodasorte.com.br</a>.</li>
                                      <li>Caso não tenha recebido o número da sorte a que tem direito, o consumidor cadastrado na promoção deverá reivindicar e/ou contestar seus números da sorte até o dia 03/01/2025 junto ao SAC da Promoção, não podendo reivindicar posteriormente.</li>
                                    </ol>
                                  </li>
                                  <li>O número da sorte será composto de 06 (seis) dígitos, sendo que o primeiro algarismo correspondente ao Número de Série e os 05 (cinco) algarismos subsequentes ao Número de Ordem, conforme exemplo abaixo:
                                    <div className="table-responsive">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th colSpan={2}>Número da sorte: 123456</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Número de Série: 1</td>
                                            <td>Número de Ordem: 23456</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </li>
                                  <li>
                                    A participação nesta promoção será dividida por regionais, onde serão sorteados 02 (dois) prêmios em cada regional.
                                    <ul>
                                      <li>SP Capital</li>
                                      <li>Interior SP</li>
                                      <li>SP Oeste</li>
                                      <li>Vale do Paraíba</li>
                                      <li>Guarulhos</li>
                                    </ul>
                                  </li>
                                  <li>
                                    Os consumidores também não poderão utilizar-se de meios escusos para participar desta promoção e/ou de mecanismos que criem condições de cadastramento irregular, desleais ou que atentem contra os objetivos e condições de participação previstas neste regulamento. As situações descritas, quando identificadas, serão consideradas como infração aos termos deste regulamento, ensejando o impedimento da participação e/ou o imediato cancelamento da inscrição do consumidor, sem prejuízo, ainda, das medidas cabíveis e/ou ação de regresso a ser promovida pelas Promotoras em face do infrator.
                                  </li>
                                </ol>
                            </li>

                            <li>
                                <h4>QUANTIDADE DE SÉRIES E ELEMENTOS SORTEÁVEIS POR SÉRIE:</h4>
                                <ol>
                                  <li>Serão emitidas 10 (dez) séries numeradas de “0” a “9”.
                                  </li>
                                </ol>
                            </li>
                            <li>
                                <h4>QUANTIDADE DE SÉRIES E ELEMENTOS SORTEÁVEIS POR SÉRIE:</h4>
                                <ol>
                                  <li>Cada série contém 100.000 (cem mil) números de ordem numerados de “00.000” (zero) a “99.999” (noventa e nove mil, novecentos e noventa e nove), totalizando 1.000.000 (um milhão) de números da sorte na promoção</li>
                                </ol>
                            </li>
                            <li>
                              <h4>APURAÇÃO E DESCRIÇÃO DE PRÊMIOS:</h4>
                              <ol>
                                <li>
                                Será distribuído 01 (um) prêmio na promoção “Bunge no fim de ano da sorte Tenda Atacado” conforme descrição abaixo:
                                  <div className="table-responsive">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th >Período de Participação</th>
                                          <th>Data da Extração da Loteria Federal</th>
                                          <th>Data da Apuração</th>
                                          <th>Qtd de Prêmios</th>
                                          <th>Descrição de cada prêmio</th>
                                          <th>Regional</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td rowSpan={10}>06/11/2024 às 00:00 a 31/12/2024 às 23:59</td>
                                          <td rowSpan={10}>08/01/2025</td>
                                          <td rowSpan={10}>09/01/2025 às 14:00</td>
                                          <td>1º Prêmio sorteado</td>
                                          <td>01 (uma) Masseira da marca Braesi modelo AES-05, no valor de R$ 5.000,00</td>
                                          <td rowSpan={2}> (SP Capital)</td>
                                        </tr>
                                        <tr>
                                          <td>2º Prêmio sorteado</td>
                                          <td>01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v, no valor de R$ 5.000,00</td>
                                        </tr>
                                        <tr>
                                          <td>1º Prêmio sorteado</td>
                                          <td>01 (uma) Masseira da marca Braesi modelo AES-05, no valor de R$ 5.000,00</td>
                                          <td rowSpan={2}> (Interior SP)</td>
                                        </tr>
                                        <tr>
                                          <td>2º Prêmio sorteado</td>
                                          <td>01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v, no valor de R$ 5.000,00</td>
                                        </tr>
                                        <tr>
                                          <td>1º Prêmio sorteado</td>
                                          <td>01 (uma) Masseira da marca Braesi modelo AES-05, no valor de R$ 5.000,00</td>
                                          <td rowSpan={2}>(SP Oeste)</td>
                                        </tr>
                                        <tr>
                                          <td>2º Prêmio sorteado</td>
                                          <td>01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v, no valor de R$ 5.000,00</td>
                                        </tr>
                                        <tr>
                                          <td>1º Prêmio sorteado</td>
                                          <td>01 (uma) Masseira da marca Braesi modelo AES-05, no valor de R$ 5.000,00</td>
                                          <td rowSpan={2}> (Vale do Paraíba)</td>
                                        </tr>
                                        <tr>
                                          <td>2º Prêmio sorteado</td>
                                          <td>01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v, no valor de R$ 5.000,00</td>
                                        </tr>
                                        <tr>
                                          <td>1º Prêmio sorteado</td>
                                          <td>01 (uma) Masseira da marca Braesi modelo AES-05, no valor de R$ 5.000,00</td>
                                          <td rowSpan={2}> (Guarulhos)</td>
                                        </tr>
                                        <tr>
                                          <td>2º Prêmio sorteado</td>
                                          <td>01 (uma) Forno de Pizza Elétrico da marca Fornetto, 220v, no valor de R$ 5.000,00</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </li>
                                <li>A apuração será realizada na Agência PQ Marketing, localizada na Rua Irará, 12 – Casa 12 – Rio Vermelho – Salvador/BA - 41940-630, conforme as datas e horários descritos na tabela acima.</li>
                              </ol>
                            </li>
                            <li>
                                <h4>PREMIAÇÃO TOTAL:</h4>
                                <div className="table-responsive">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Quantidade Total de Prêmios</th>
                                        <th>Valor total da Promoção R$</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>10</td>
                                        <td>R$ 50.000,00</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>                                
                            </li>
                            <li>
                              <h4>FORMA DE APURAÇÃO:</h4>
                              <ol>
                                  <li>A apuração do resultado da promoção <b>“Bunge no fim de ano da sorte Tenda Atacado”</b> será com base no resultado da loteria federal, conforme previsto neste Regulamento.</li>
                                  <li>Será considerado o contemplado, o elemento sorteável (“Número da Sorte” composto por 06 (seis) algarismos subsequentes que compõem o número de série formado pela dezena simples do 1º bilhete, lidos verticalmente de cima para baixo, seguidos pelo número de ordem formado pela unidade do 1º ao 5º bilhete, lidos verticalmente de cima para baixo, conforme exemplo abaixo:

                                    <h5>Exemplo I:</h5>
                                    1o prêmio 123<span className="blue">0</span><span className="red">5</span><br /> 
                                    2º prêmio 0543<span className="red">7</span><br /> 
                                    3º prêmio 1352<span className="red">6</span><br /> 
                                    4º prêmio 0232<span className="red">9</span><br /> 
                                    5º prêmio 1545<span className="red">8</span><br /> 

                                    Exemplo de número de série: <span className="blue">0</span><br /> 
                                    Exemplo de número de ordem: <span className="red">57698</span><br /> 
                                    Exemplo de número da sorte contemplado: <span className="blue">0</span> <span className="red">57698</span>
                                  </li>
                                  <li>Na eventualidade do Número da Sorte sorteado (ex.: <span className="blue">0</span> <span className="red">57698</span>) não ter sido atribuído, o prêmio caberá ao portador do número da sorte imediatamente superior ou, na falta deste, novamente superior e assim sucessivamente até que se encontre a quantidade de números da sorte contemplados de acordo com a quantidade de prêmios sorteados na apuração.
                                    <ol>
                                      <li>
                                        A título exemplificativo: O critério para identificar o número da sorte contemplado será por aproximação inicialmente para cima com base no número da sorte sorteado (ex.: <span className="blue">0</span> <span className="red">57698</span>), de forma “Crescente”,  ou seja, caso o número da sorte (ex.:<span className="blue">0</span> <span className="red">57698</span>) não tenha disso distribuído na promoção o prêmio será entregue ao número imediatamente superior crescente (ex.:<span className="blue">0</span> <span className="red">57699</span>), caso o mesmo também não tenha sido distribuído na promoção será aplicado novamente a regra “ imediatamente superior crescente” (ex.: <span className="blue">0</span> <span className="red">57700</span>) até que se encontre a quantidade de números da sorte distribuídos em igualdade a quantidade de prêmios a ser distribuídos no respectivo sorteio.
                                      </li>
                                      <li>
                                        Caso a utilização da regra prevista no item anterior resulte no alcance do limite superior (ex.: <span className="blue">99</span><span className="red">99999</span>) do número da sorte, sem a obtenção da quantidade necessária de número da sorte distribuído em relação a quantidade de prêmios sorteado, a distribuição dos prêmios continuará a partir do número da sorte inicial (ex.: <span className="blue">0</span><span className="red">57698</span>), ou seja, (ex.: <span className="blue">0</span><span className="red">57697</span>) caso o mesmo também não tenha sido distribuído na promoção será aplicado novamente a regra “imediatamente superior crescente” (ex.: <span className="blue">0</span><span className="red">57696</span>) e assim sucessivamente até que se encontre a quantidade de números da sorte distribuídos em igualdade a quantidade de prêmios a ser distribuídos no respectivo sorteio.
                                      </li>                                      
                                    </ol>
                                  </li>
                                  <li>Caso a Extração da Loteria Federal não venha a ocorrer na data prevista, por qualquer motivo, será considerada para efeito de apuração do resultado desta promoção, a data da Extração da Loteria Federal subsequente, sendo que no caso de acúmulo de sorteios utilizando a mesma extração da Loteria Federal à distribuição dos prêmios será sequencial e cumulativa, prevalecendo a ordem decrescente dos prêmios, com base no valor dos prêmios.</li>
                                  <li>Caso todas as séries aqui previstas sejam utilizadas antes do prazo previsto de término da Promoção, a Empresa Promotora solicitará autorização para aumento da quantidade de séries junto à SPA/MF, através de aditamento que deverá ser encaminhado à SPA/MF em um prazo mínimo de 10 (dez) dias antes do encerramento das séries. Caso o aditamento não seja autorizado pela SPA/MF, a Empresa Promotora deverá informar o encerramento da Promoção, através dos mesmos meios de divulgação da Promoção, imediatamente após o término das séries.</li>
                                </ol>
                            </li>
                            <li>
                                <h4>CRITÉRIOS DE DESCLASSIFICAÇÃO:</h4>
                                <ol>
                                  <li>Presume, ainda, as Promotoras que as participações dos consumidores devam ser originadas de endereços (físicos ou lógicos) diferentes para consumidores diferentes, e a aglutinação de participações oriundas de endereços iguais presume a formação de associação para participação, que eventualmente, possa estar vedada pelo regulamento da promoção e que, portanto, será investigada com mais rigor, para definir sua adesão a estas regras, sob pena de desclassificação.</li>
                                  <li>Serão desclassificadas automaticamente, no ato da validação do resultado da Loteria Federal, as inscrições dos consumidores participantes que:
                                    <ul>
                                      <li>Tenham feito o preenchimento do cadastro incompleto e/ou incorreto.</li>
                                      <li>Não apresente o cupom fiscal cadastrado na promoção ou apresente o cupom fiscal que não atenda aos requisitos desta promoção.</li>
                                      <li>Esteja incluído na lista daqueles que se encontram proibidos de participar desta promoção, em razão da existência de algum vínculo com as Empresas Promotoras, nos termos descritos no item abaixo.</li>
                                      <li>Consumidores que tentarem fraudar ou burlar as regras estabelecidas neste regulamento e/ou utilizar de meios mecânicos, robóticos ou fraudulentos para interferir no resultado da promoção.</li>
                                    </ul>
                                  </li>
                                  <li>Fica vedada a participação dos funcionários das Empresas Promotoras, bem como os funcionários das agências e outras empresas diretamente envolvidas nos processos de planejamento, elaboração e promoção desta campanha. O atendimento ao acima disposto será de inteira responsabilidade das Empresas Promotoras no momento da apuração, que automaticamente desclassificaram os nomes de consumidores impedidos mediante a verificação do CPF/ME destes. Sendo que um novo contemplado será apurado em seu lugar, seguindo a regra de contemplação na promoção.</li>
                                  <li>Os consumidores poderão ser excluídos automaticamente da promoção em caso de comprovação de fraude, de não preenchimento dos requisitos previamente determinados e/ou em decorrência da prestação de informações incorretas ou equivocadas, de acordo com as regras do regulamento da promoção.</li>
                                  <li>A comprovação de idoneidade, de caráter desclassificatório, será procedida pelas Empresas Promotoras mediante a análise dos documentos requeridos, apresentados em conformidade com as informações previamente disponibilizadas quando da realização da inscrição nesta Promoção. A prática de ato tipificado como ilícito penal, se identificada, será imediatamente comunicada ao Ministério da Fazenda/SPA, sendo neste caso o consumidor será substituído por outro consumidor contemplado (cupons suplentes) e assim sucessivamente até que se encontre um contemplado que atenda as regras deste regulamento.</li>
                                  <li>Uma vez identificada a prática de ato tipificado como ilícito penal, o contemplado responderá pelo crime de falsificação de documentos ou uso de documento falso, sem prejuízo de responder civilmente por seus atos.</li>
                                </ol>
                            </li>
                            <li>
                                <h4>FORMA DE DIVULGAÇÃO DO RESULTADO:</h4>
                                <ol>
                                  <li>O nome e número da sorte do contemplado será disponibilizado para consulta no hotsite <a href="https://www.fimdeanodasorte.com.br" target="_blank">www.fimdeanodasorte.com.br</a> no prazo de até 10 (dez) dias, a contar da data da respectiva apuração e nestes permanecerão pelos próximos 30(trinta) dias subsequentes.</li>

                                  <li>As Empresas Promotoras comunicarão o consumidor contemplado sobre a classificação e premiação nesta Promoção no prazo de até 10 (dez) dias, a contar da data da respectiva apuração.</li>

                                  <li>O contato com o consumidor contemplado será realizado por meio de ligação telefônica e/ou e-mail momento no qual as Empresas Promotoras lhes informaram o procedimento para o recebimento do prêmio, disponibilizando, de imediato, um canal exclusivo de atendimento.</li>

                                  <li>O consumidor contemplado, desde já, autoriza o uso, pelas Empresas Promotoras, pelo período de 12 (doze) meses, a contar da data da apuração do resultado desta Promoção, de suas imagens, som de voz, desempenho e nome, em filmes publicitários veiculados em mídia eletrônica, internet, fotos, cartazes, anúncios em jornais, revistas ou em qualquer outra forma de mídia impressa ou eletrônica, a serem veiculados no Território Nacional, referentes à divulgação da conquista do prêmio, sem qualquer ônus às Empresas Promotoras. Esta autorização é celebrada em caráter gratuito, renunciando o consumidor contemplado expressamente ao direito de reivindicar qualquer vantagem pecuniária, presente ou futuramente, decorrente da divulgação de sua imagem e voz pelas Empresas Promotoras.</li>

                                  <li>O consumidor contemplado, declara, estar ciente de que as Empresas Promotoras não serão responsabilizadas pelo uso indevido das imagens e/ou informações objeto deste termo de autorização em exibições e/ou reproduções não autorizadas, se captadas indevidamente por terceiros, ainda que durante eventos e programas por elas desenvolvidos ou ocorridos em suas dependências.
                                  </li>
                                </ol>
                            </li>
                            <li>
                                <h4>ENTREGA DOS PRÊMIOS:</h4>
                                <ol>
                                  <li>O prêmio é pessoal e intransferível e será entregue em data e horário a ser combinado com o consumidor contemplado, em local e horários ajustado de comum acordo entre as partes no prazo de até 30 (trinta) dias contados da data da apuração da promoção, de acordo com o Artigo 5º do Decreto 70.951/72. Esse prazo poderá ser prorrogado em se tratando de atraso decorrente dos fornecedores dos prêmios, não caracterizando qualquer descumprimento por parte das Empresas Promotoras.</li>
                                  <li>Para a entrega do prêmio, o consumidor contemplado deverá apresentar documentação comprobatória <b>original</b> do RG/CNH, CPF, comprovante de endereço e Contrato Social (caso seja pessoa jurídica). O prêmio não poderá ser entregue a terceiros.</li>
                                  <li>Caberá, ao consumidor contemplado, submeter à análise das Empresas Promotoras os documentos a seguir relacionados, para a confirmação dos dados pessoais RG, CPF e comprovante do domicílio, no prazo de até 72 (setenta e duas) horas do efetivo contato. O atendimento ao acima disposto será de inteira responsabilidade do consumidor, e caso o mesmo não atenda às regras expostas neste regulamento, o mesmo será automaticamente desclassificado.</li>
                                  <li>Após a confirmação dos dados do consumidor contemplado, decorrente do contato a respeito da contemplação, o prêmio será entregue livre e desembaraçado de quaisquer ônus.</li>
                                  <li>Caberá, igualmente, ao consumidor contemplado, na ocasião do recebimento do prêmio, a assinatura do Recibo de Entrega do Prêmio, o qual deverá ser devolvido às Empresas Promotoras como comprovante de regularidade desta campanha promocional.</li>
                                  <li>Nos termos da legislação em vigor, não será permitida a conversão do prêmio em dinheiro ou, ainda, a sua substituição por outro, bem como a transferência do prêmio à terceiro, que não o consumidor contemplado, em data anterior à sua efetiva entrega e integralização ao patrimônio deste.</li>
                                  <li>As obrigações e responsabilidade, se houver, da Empresa Promotora com os consumidores ganhadores encerra-se no momento da entrega do prêmio, que será comprovada mediante a assinatura do recibo de entrega do prêmio, não cabendo ao contemplado discutir ou redefinir as condições e/ou premissas da promoção ou do prêmio.</li>
                                  <li>Será resguardado o direito do contemplado de reclamar seu prêmio no prazo de até 180 (cento e oitenta) dias, a contar a partir da data da respectiva apuração. Caso o prêmio ganho não seja reclamado no prazo caducará o direito do respectivo titular e o valor correspondente será recolhido pelas Empresas Promotoras, ao Tesouro Nacional como Renda da União, no prazo subsequente de 10 (dez) dias conforme art. 6º do decreto 70.951/72.
                                  </li>
                                  
                                </ol>
                            </li>
                            <li>
                                <h4>DISPOSIÇÕES GERAIS:</h4>
                                <ol>
                                  <li>O regulamento desta Promoção será disponibilizado no hotsite <a href="https://www.fimdeanodasorte.com.br" target="_blank">www.fimdeanodasorte.com.br</a>.</li>
                                  <li>Ao se cadastrar nesta promoção, o consumidor aceita todos os termos deste Regulamento e autoriza o uso dos seus dados pessoais nos termos do Regulamento e as Empresas Promotoras desde já, informam que tratarão os Dados Pessoais compartilhados e/ou transferidos de acordo com as disposições da LGPD e todas as demais Leis de Proteção de Dados Pessoais aplicáveis a este Regulamento.</li>
                                  <li>Ademais, as Empresas Promotoras reconhecem que o compartilhamento e/ou a transferência de Dados Pessoais estão sujeitos à existência de uma base legal válida para o tratamento, nos termos das Leis, considerando o objetivo original da coleta, e que o titular dos Dados Pessoais deve ser devidamente informado da possibilidade do compartilhamento e/ou da transferência.</li>
                                  <li>A Promotora exige que todas as empresas responsáveis pela execução e operacionalização desta promoção utilizem referidos dados pessoais em conformidade com este Regulamento e como a Lei Geral de Proteção de Dados (Lei nº 13.709/2018). </li>
                                  <li>O consumidor que der “optin” no momento do cadastro autorizará a Empresa Promotora a sua utilização para fins de recebimento de informes sobre campanhas promocionais, envio de ofertas e demais campanhas que serão promovidas em outro momento oportuno. Os consumidores reservam-se o direito de revogar esta autorização a qualquer momento mediante aviso prévio/solicitação à Empresa Promotora.  </li>
                                  <li>A Promotora, neste momento, assume o compromisso de proteger os dados pessoais cadastrados, mantendo absoluta confidencialidade sobre tais informações, garantindo que, excetuados os casos previstos em lei e ao fiel cumprimento da execução desta promoção, não serão compartilhados ou cedidos a terceiros a qualquer título. </li>
                                  <li>Assim, os dados serão compartilhados apenas com as empresas contratadas pela Promotora, tais como: empresas responsáveis pelo sistema do banco de dados e por gerar os números da sorte, pela contabilidade, pela auditoria, pela autorização e prestação de contas da promoção junto à SPA/MF, pela assessoria jurídica, pela entrega do prêmio, todas com a finalidade exclusiva de executar e operacionalizar esta promoção. Os dados também serão compartilhados com a SPA/MF, órgão público responsável pela autorização, regulação e fiscalização das promoções comerciais, em atenção à legislação que rege o tema.</li>
                                  <li>As Empresas Promotoras garantem que seus empregados, subcontratados, prestadores de serviços e demais trabalhadores ou ainda, quaisquer pessoas envolvidas na execução do objeto deste regulamento, vinculada direta ou indiretamente sob sua responsabilidade também cumprirão o disposto neste regulamento quanto a confidencialidade dos dados pessoais.</li>
                                  <li>Os dados pessoais coletados para esta promoção ficarão armazenados para fins operacionais e obedecerão a padrões rígidos de confidencialidade e segurança. Nenhum documento, informação e/ou dado pessoal será divulgado e/ou compartilhado em nenhuma hipótese, salvo os casos acima previstos e/ou mediante ordem judicial ou por determinação regulatória ou legal. </li>
                                  <li>Internamente, os dados dos consumidores serão acessados somente por colaboradores autorizados pela Promotora, respeitando os princípios inerentes ao tratamento de dados pessoais previstos na Lei Geral de Proteção de Dados, sempre com o objetivo de execução e operacionalização desta Promoção, além do compromisso de confidencialidade e preservação da privacidade, de acordo com este Regulamento. </li>
                                  <li>E em atenção às diretrizes legais aplicáveis, a Empresa Promotora possibilitará aos consumidores que revoguem a autorização para uso de seus dados, para fins de execução desta promoção, concedida nos termos do regulamento, bastando que solicitem pelo e-mail atendimento3@porquemkt.com.br. </li>
                                  <li>Na hipótese de a promoção ainda estar em curso, a revogação da autorização, pelos consumidores, acarretará na sua imediata desclassificação e na cessação do envio de mensagens com os fins específicos descritos neste Regulamento. </li>
                                  <li>Ao término da promoção, os dados pessoais de todos os consumidores serão mantidos no banco de dados da Promotora pelo prazo de até 10 (dez) anos, ou até que haja o cancelamento, de forma expressa, das autorizações de manutenção dos dados previstas no Regulamento, considerando o fato que ocorrer primeiro, sem prejuízo do disposto no item abaixo. </li>
                                  <li>A Promotora, para fins de cumprimento legal e/ou defesa em eventual processo administrativo e/ou judicial, manterá, obrigatoriamente, em sua base de dados, os dados pessoais: (i) dos consumidores contemplados: pelo prazo de 5 (cinco) anos, contados do término da promoção; e (ii) dos demais consumidores inclusive daqueles que cancelaram a autorização para participar desta promoção: até o recebimento, pela Promotora, do ofício de homologação da prestação de contas a ser realizada perante a SPA/MF, no prazo legal. Findos os prazos ora estipulados, os dados poderão ser deletados. </li>
                                  <li>Conforme o disposto no art. 70, inciso 1º, “b”, da Lei nº. 11.196, de 21/11/05, a instituição promotora recolherá 20% (vinte por cento) de IRRF sobre o valor do prêmio, até o 3º (terceiro) dia útil subsequente ao decêndio de ocorrência dos fatos geradores, através de DARF, recolhida na rede bancária, com o código 0916.</li>
                                  <li>As dúvidas, omissões ou controvérsias oriundas desta Promoção serão, preliminarmente, dirimidas por uma comissão composta por 03 (três) representantes das Empresas Promotoras. Na eventualidade de não se atingir um consenso após a atuação da comissão, a questão deverá, então, ser submetida à apreciação da SPA/MF. No silêncio injustificado das Empresas Promotoras, bem como em razão de decisão insatisfatória que esta vier a adotar quanto a eventuais solicitações de esclarecimentos que lhe forem apresentadas, poderão os consumidores participantes da Promoção, apresentar suas reclamações fundamentadas ao Procon local e/ou aos órgãos públicos integrantes do Sistema Nacional de Defesa do Consumidor. </li>
                                  <li>Findo o prazo da Promoção e/ou esgotado os números de sorte, qualquer compra/pagamento efetuado pelo consumidor, mesmo que atenda aos demais requisitos deste Regulamento, não ensejará ao mesmo o recebimento de qualquer número da sorte, brinde, dinheiro, troca por outro produto ou devolução do dinheiro gasto com os Produtos Participantes ou reembolso de qualquer outra despesa aqui não prevista.</li>
                                  <li>A Empresa Promotora encaminhará à SPA/MF a Lista de consumidores participantes, anexando na aba "Apurações" do sistema SCPC, os nomes e números da sorte distribuídos, após o término de cada período de participação e antes da extração da Loteria.</li>
                                  <li>Fica, desde já, eleito o foro central da Comarca do consumidor para solução de quaisquer questões referentes ao Regulamento desta Promoção.</li>
                                  <li>O número do Certificado de Autorização constará de forma clara e precisa no site da promoção. Para os demais materiais de divulgação, as Empresas Promotoras solicitaram dispensa da aposição, fazendo constar, apenas, a indicação de consulta do número de autorização SPA/MF no referido site.</li>
                                  <li>Esta promoção está de acordo com a legislação vigente (Lei n.º 5.768/71, regulamentada pelo Decreto n.º 70.951/72 e Portaria MF 7.638/22) e obteve o <b>Certificado de Autorização SPA/MF nº. 04.037980/2024</b> expedido pelo Ministério da Fazenda. </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                
              </div>
            </Container>
            
          </section>
        </div>
      </div>
      <FooterComponent />
    </>
  );
}

export default RulesPage;
