import { Col, Container, Image, Row, Accordion } from "react-bootstrap";
import PageContentService from "../../../services/PageContentService";
import { useEffect, useState } from "react";
import { IPageContent } from "../../../interfaces/IPageContent";
import PageContent from "../../../components/PageContent";


const FaqComponent = () => {
  

  return (
    <section id="faq" className="sec sec_faq">
      <div className="selos selo-divider">
        <div className="selo-5un selo">
          <Image
            src="./images/selo-divider.png"
            className="img-fluid"
          />
        </div>
        <div className="selo selo-numeroextra">
          <Image
            src="./images/selectprod.png"
            className="img-fluid"
          />
        </div>
      </div>
    
      <Container>
       
        <div className="sec_content">
          <header className="sec_header mb-4">
            <h2 className="title sec_title">PERGUNTAS FREQUENTES</h2>
          </header>
          <Row>
            <Col lg="10">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Do que eu preciso para concorrer?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                    Para participar da promoção <b>“Bunge no Fim de ano da sorte Tenda Atacado”</b>, o cliente deve efetuar compras a partir de 05 (cinco) unidades ou 01 (um) fardo de produtos da família Suprema, nas lojas Tenda Atacado em produtos da família Suprema, e cadastrar o cupom fiscal no site da promoção  <a href="https://www.fimdeanodasorte.com.br" target="_blank">www.fimdeanodasorte.com.br</a>. Caso suas compras contenham ao menos um produto das marcas participantes da promoção, as chances de ganhar são dobradas. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Posso cadastrar uma nota de qualquer data de compra?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Só poderão ser cadastradas as notas cujas compras foram feitas no período de 06 de novembro de 2024 até o dia 31 de dezembro de 2024. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Posso me cadastrar mais de uma vez?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                    Não há limite de chances por participante, podendo concorrer quantas vezes realizar as compras acima de 05 (cinco) unidades ou 01 (um) fardo de produtos da família Suprema, desde que atenda as condições previstas no regulamento.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Qual o prêmio e como eu posso utilizá-lo?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                    O prêmio é de 01 (um) masseira da marca Braesi modelo AES-05, ou forno de pizza Elétrico da marca Fornetto, 220v, vinculado ao CPF do participante, sendo 01 (um) prêmio por CPF. </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    Pessoas de qualquer idade podem participar?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                    Qualquer pessoa física com idade igual ou superior a 18 (dezoito) anos pode participar.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Ganhei o vale compras mais quero dar para outra pessoa, é possível?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                    Ficam os participantes cientes desde já, que a participação na promoção é individualizada e não poderá, em hipótese alguma, transferir e/ou dividir com outro participante, independentemente do grau de parentesco e/ou amizade. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    Quais são as lojas participantes? 
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                    Todas, inclusive o canal digital e televendas.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default FaqComponent;
