import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";

const NavbarWithAnimation = () => {
  const [position, setPosition] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.scrollY;
      if(moving > 80 ){
        //console.log('esconder menu');
        setVisible(position > moving);
      }
      //setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const cls = visible ? "visible" : "hidden";

  return (
    <Navbar
      expand="lg"
      fixed="top"
      className={`navbar_main ${cls}`}
      variant="dark"
      bg="dark" 
      //data-bs-theme="dark"
      collapseOnSelect
    >
      <Container>
        {/* <Navbar.Brand href="/" className="d-lg-none d-none" >
          <Image src="./images/logo-bunge-white.png" className="main_logo w-100" />
        </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="main-navbar-nav" />
        <Navbar.Collapse id="main-navbar-nav">
          <Nav className="justify-content-md-between w-100  text-uppercase">
            <Nav.Item>
              <Nav.Link className="nav-link" href="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="nav-link" href="/#como-participar">
                Mecânica
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link className="nav-link" href="/#marcas-participantes">
                Parceiros
              </Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
              <Nav.Link className="nav-link" href="/regulamento">
                Regulamento
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="nav-link" href="/meus-numeros-da-sorte">
                Números da Sorte
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="nav-link" href="/ganhadores">
                Ganhadores
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="nav-link" href="/#faq">
                FAQ
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarWithAnimation;
