import { Container, Image } from "react-bootstrap";

const FooterComponent = () => {
  return (
    <footer className="main-footer bg-color-2 " id="mainFooter">
      <Container>
        <div className="row">

          <div className="col-md-6 pb-4 ">
            
              <div className="social-nav">
                <ul className="nav">
                  <li className="social-item">
                    <a
                      href="https://www.facebook.com/BungeProfissional/?locale=pt_BR"
                      target="_blank"
                    >
                      {" "}
                      <i className="icon-facebook"></i>{" "}
                    </a>
                  </li>
                  <li className="social-item">
                    <a
                      href="https://www.instagram.com/bungeprofissional"
                      target="_blank"
                    >
                      {" "}
                      <i className="icon-instagram"></i>{" "}
                    </a>
                  </li>
                  
                  <li className="social-item">
                    <a
                      href="https://www.youtube.com/@BungeProfissional"
                      target="_blank"
                    >
                      {" "}
                      <i className="icon-youtube-play"></i>{" "}
                    </a>
                  </li>
                </ul>
              </div>

          </div>

          <div className="col-md-6">
              
            <div className="contatos  ">
              <p className="mb-0">
                Para dúvidas ou Suporte entre em contato através dos nossos canais
                de comunicação:
              </p>
                  <a href="https://api.whatsapp.com/send?phone=5508001128643
" target="_blank" className="btn btn-cta"> <Image src="./images/contact-img.png" className="wp-contact"></Image> </a>
            </div>
          </div>
          <div className="col-md-12">
            <div className="legal-text mt-4">
              “Imagens meramente ilustrativas. Promoção autorizada pelo Ministério da Fazenda/SPA, válida de 06/11/2024 a 31/12/2024, consulte descrição, valor dos prêmios, período e condições de participação, produtos participantes, regulamento completo e outras informações no site <a href="https://www.fimdeanodasorte.com.br" target="_blank">www.fimdeanodasorte.com.br</a>”.
            </div>
          </div>
        </div>
        
      </Container>
    </footer>
  );
};

export default FooterComponent;
